import { Component, Vue } from 'vue-property-decorator';
import settings from "@/settings";
import { ElTabPane } from 'element-ui/types/tab-pane';

@Component({})
export default class extends Vue {
  private activeTab = 'titolo-corso';
  forms: { title: string; value: string; formKey: string; }[] = []

  get currentForm(): { title: string; value: string; formKey: string; } {
    return this.forms.find(f => f.value === this.activeTab);
  }

  created() {
    this.forms = settings.appSetting.richiesteSpeciali;
    if (this.forms && this.forms.length > 0)
      this.activeTab = this.forms[0].value;
  }

  form: any = null;
  mounted() {
    this.instanceLiveAgent();
  }

  instanceLiveAgent() {
    const _self = this;
    this.ctorLiveAgent(document, 'https://servicedesk.teamdev.it/scripts/track.js', function (e) {
      var createdForm = (window as any).LiveAgent.createForm(_self.currentForm.formKey, e);
      setTimeout(() => {
        _self.form = document.getElementById(createdForm.elementId).outerHTML;
      }, 1800);
    });
  };

  ctorLiveAgent(d, src, c) {
    var t = d.scripts[d.scripts.length - 1], s = d.createElement('script'); 
    s.id = 'la_x2s6df8d'; 
    s.defer = true; 
    s.src = src; 
    s.onload = s.onreadystatechange = function () { var rs = this.readyState; if (rs && (rs != 'complete') && (rs != 'loaded')) { return; } c(this); }; t.parentElement.insertBefore(s, t.nextSibling);
  };

  handleClick(tab: ElTabPane, event) {
    this.form = null;
    if (this.currentForm) {
      this.instanceLiveAgent();
    }
  }

}
