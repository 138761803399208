import { Vue, Component, Prop, Inject, Watch } from "vue-property-decorator";
import { rolesServices } from "../../services/rolesServices";
// import { userService } from "@/modules/users/services/userService";
// import { rolesServices } from "@/modules/roles/services/rolesServices";


@Component({})
export default class roleAutocomplete extends Vue {

  @Prop()
  value?: string;

  @Prop({ default: false })
  clearable?: boolean
  @Prop({ default: false })
  defaultFirstOption: boolean
  @Prop({ default: `Ricerca il ruolo` })
  placeholder?: string
  @Prop({})
  size?: 'medium' | 'mini' | 'small'
  @Prop({})
  className?: string
  @Prop({default: false})
  disabled: boolean

  loading: boolean = false;
  options: { value: string, label: string }[] = [];

  public get selected(): string {
    return this.value;
  }
  public set selected(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  created() {
    this.initDefaultSelect();
  }

  @Watch("value")
  async valueChange(n, o) {
    if (n !== o) {
      await this.initDefaultSelect()
    }
  }

  async initDefaultSelect() {
    if (this.value) {
      // const s = await userService.Detail(this.value);
      const r = await rolesServices.getRoleByName(this.value);
      if (!r) {
        this.selected = null;
      } else if (this.options.findIndex(f => f.value === r.name) < 0) {
        this.options.push({ label: r.name, value: r.name })
      }
    } else {
      if (this.selected) this.selected = null;
    }
  }

  async remoteMethod(query) {
    if (query !== '') {
      this.loading = true;
      // const users = await userService.List(query, 0, 20);
      const roles = await rolesServices.getRoles();
      this.options = roles.map(r => { return { label: r.name, value: r.name } });
      this.loading = false;
    } else {
      this.options = [];
    }
  }

  // @Prop()
  // allRoles: server.Roles[];

  // filter:string = "";
  // isSelected: boolean = false;

  // querySearch(filter:string,cb:any){
  //   const rolelist  = this.allRoles.map(m => {return {value:m.name,name:m.name}});
  //   var res = filter ? rolelist.filter(this.createFilter(filter)) : rolelist;
  //   cb(res);
  // }

  // handleSelect(item: {value:string,name:string}) {
  //   this.$emit('input',item.name);
  //   this.isSelected = true;
  // }

  // createFilter(queryString) {
  //   return (link) => {
  //     return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
  //   };
  // }

}