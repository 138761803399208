import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'
import { generateKey } from '@/utils/utils';
import { enteService } from '@/modules/enti/service/enteService';
import { formatTime, uppercaseFirstChar } from '@/filters';
import { export2Excel } from '@/utils/excel';

class ExportUsersService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => `${settings.baseUrl}/identity/`;
  }


  public async Stats(enteId: string): Promise<exportUsers.stats> {
    return await this.Get(`export-users-ente/stats`, { enteId });
  }

  public async List(enteId: string): Promise<exportUsers.user[]> {
    return await this.Get(`export-users-ente`, { enteId });
  }

  public async Export(enteId: string) {
    const ente = await enteService.Detail(enteId);

    const data = await this.Stats(enteId);

    const __filename = `${`${ente.nome.replace(/ /gi, '-').toLowerCase()}`}_${formatTime(new Date(), 'YYYYMMDDHHmmss')}`;

    const tables: any[] = [];
    tables.push({
      header: ['              ', 'Totali'],
      data: [
        ['Registrati', data.counters.iscritti.toString()],
        ['Non Registrati', data.counters.nonIscritti.toString()],
        ['Totali', (data.counters.iscritti + data.counters.nonIscritti).toFixed()]
      ],
      wsName: `Statistiche utenti`,
      merges: [],
      autoWidth: true
    });

    tables.push({
      header: [
        'Nome utente',
        'Data Creazione Utenza',
        'E-Mail confermata',
        'Utente bloccato',
        'Accesso tramite SPID',
        'Nome completo',
        'Cognome',
        'Nome',
        'Codice fiscale',
        'Ordine Regionale',
        'Adempiente triennio formativo precedente',
        'Obbligo formativo',
        'Data di nascita',
        'Comune di nascita',
        'E-Mail',
        'PEC',
        'Telefono',
        'Tessera',
        'Categoria',
        'Data prima iscrizione',
        'Data iscrizione categoria',
        'Data ultimo provvedimento',
        'Data inizio rinuncia',
        'Data fine rinuncia',
        'Data inizio cancellazione',
        'Data fine cancellazione',
        'Data pensione',
        'Data decesso',
        'Data iscrizione regione',
        'Indirizzo',
        'CAP',
        'Comune',
        'Provincia',
        'Nazione'
      ],
      data: data.iscritti.map((m) => [
        m.userName,
        m.userCreationDate,
        m.emailConfirmed,
        m.lockoutEnabled,
        m.spid,
        m.nomeCompleto,
        m.cognome,
        m.nome,
        m.codiceFiscale,
        m.ordineRegionale,
        m.adempiente == 2 ? 'Nuovo Iscritto' : m.adempiente == 1 ? 'Adempiente' : 'Non adempiente',
        m.obbligoFormativo ? uppercaseFirstChar(m.obbligoFormativo) : null,
        !m.dataNascita || (m.dataNascita && m.dataNascita.getFullYear() > 1800) ? m.dataNascita : 'Data non valida',
        m.comuneNascita,
        m.mail,
        m.pec,
        m.telefono,
        m.tessera,
        !m.categoria || (m.categoria && !m.categoria.indexOf('_SIGEF')) ? m.categoria : m.categoria.replace('_SIGEF', ''),
        !m.dataPrimaIscrizione || (m.dataPrimaIscrizione && m.dataPrimaIscrizione.getFullYear() > 1900) ? m.dataPrimaIscrizione : 'Data non valida',
        !m.dataIscrizioneCategoria || (m.dataIscrizioneCategoria && m.dataIscrizioneCategoria.getFullYear() > 1900) ? m.dataIscrizioneCategoria : 'Data non valida',
        !m.dataProvvedimento || (m.dataProvvedimento && m.dataProvvedimento.getFullYear() > 1900) ? m.dataProvvedimento : 'Data non valida',
        !m.dataInizioRinuncia || (m.dataInizioRinuncia && m.dataInizioRinuncia.getFullYear() > 1900) ? m.dataInizioRinuncia : 'Data non valida',
        !m.dataFineRinuncia || (m.dataFineRinuncia && m.dataFineRinuncia.getFullYear() > 1900) ? m.dataFineRinuncia : 'Data non valida',
        !m.dataInizioCancellazione || (m.dataInizioCancellazione && m.dataInizioCancellazione.getFullYear() > 1900) ? m.dataInizioCancellazione : 'Data non valida',
        !m.dataFineCancellazione || (m.dataFineCancellazione && m.dataFineCancellazione.getFullYear() > 1900) ? m.dataFineCancellazione : 'Data non valida',
        !m.dataPensione || (m.dataPensione && m.dataPensione.getFullYear() > 1900) ? m.dataPensione : 'Data non valida',
        !m.dataDecesso || (m.dataDecesso && m.dataDecesso.getFullYear() > 1900) ? m.dataDecesso : 'Data non valida',
        !m.dataIscrizioneRegione || (m.dataIscrizioneRegione && m.dataIscrizioneRegione.getFullYear() > 1900) ? m.dataIscrizioneRegione : 'Data non valida',
        m.indirizzo,
        m.cap,
        m.comune,
        m.provincia,
        m.nazione
      ]),
      wsName: `Giornalisti Registrati`,
      merges: [],
      autoWidth: true
    });

    tables.push({
      header: [
        'Nome completo',
        'Cognome',
        'Nome',
        'Codice fiscale',
        'Ordine Regionale',
        'Adempiente triennio formativo precedente',
        'Obbligo formativo',
        'Data di nascita',
        'Comune di nascita',
        'E-Mail',
        'PEC',
        'Telefono',
        'Tessera',
        'Categoria',
        'Data prima iscrizione',
        'Data iscrizione categoria',
        'Data ultimo provvedimento',
        'Data inizio rinuncia',
        'Data fine rinuncia',
        'Data inizio cancellazione',
        'Data fine cancellazione',
        'Data pensione',
        'Data decesso',
        'Data iscrizione regione',
        'Indirizzo',
        'CAP',
        'Comune',
        'Provincia',
        'Nazione'
      ],
      data: data.nonIscritti.map((m) => [
        m.nomeCompleto,
        m.cognome,
        m.nome,
        m.codiceFiscale,
        m.ordineRegionale,
        m.adempiente == 2 ? 'Nuovo Iscritto' : m.adempiente == 1 ? 'Adempiente' : 'Non adempiente',
        m.obbligoFormativo ? uppercaseFirstChar(m.obbligoFormativo) : null,
        !m.dataNascita || (m.dataNascita && m.dataNascita.getFullYear() > 1800) ? m.dataNascita : 'Data non valida',
        m.comuneNascita,
        m.mail,
        m.pec,
        m.telefono,
        m.tessera,
        !m.categoria || (m.categoria && !m.categoria.indexOf('_SIGEF')) ? m.categoria : m.categoria.replace('_SIGEF', ''),
        !m.dataPrimaIscrizione || (m.dataPrimaIscrizione && m.dataPrimaIscrizione.getFullYear() > 1900) ? m.dataPrimaIscrizione : 'Data non valida',
        !m.dataIscrizioneCategoria || (m.dataIscrizioneCategoria && m.dataIscrizioneCategoria.getFullYear() > 1900) ? m.dataIscrizioneCategoria : 'Data non valida',
        !m.dataProvvedimento || (m.dataProvvedimento && m.dataProvvedimento.getFullYear() > 1900) ? m.dataProvvedimento : 'Data non valida',
        !m.dataInizioRinuncia || (m.dataInizioRinuncia && m.dataInizioRinuncia.getFullYear() > 1900) ? m.dataInizioRinuncia : 'Data non valida',
        !m.dataFineRinuncia || (m.dataFineRinuncia && m.dataFineRinuncia.getFullYear() > 1900) ? m.dataFineRinuncia : 'Data non valida',
        !m.dataInizioCancellazione || (m.dataInizioCancellazione && m.dataInizioCancellazione.getFullYear() > 1900) ? m.dataInizioCancellazione : 'Data non valida',
        !m.dataFineCancellazione || (m.dataFineCancellazione && m.dataFineCancellazione.getFullYear() > 1900) ? m.dataFineCancellazione : 'Data non valida',
        !m.dataPensione || (m.dataPensione && m.dataPensione.getFullYear() > 1900) ? m.dataPensione : 'Data non valida',
        !m.dataDecesso || (m.dataDecesso && m.dataDecesso.getFullYear() > 1900) ? m.dataDecesso : 'Data non valida',
        !m.dataIscrizioneRegione || (m.dataIscrizioneRegione && m.dataIscrizioneRegione.getFullYear() > 1900) ? m.dataIscrizioneRegione : 'Data non valida',
        m.indirizzo,
        m.cap,
        m.comune,
        m.provincia,
        m.nazione
      ]),
      wsName: `Giornalisti non Registrati`,
      merges: [],
      autoWidth: true
    });

    export2Excel(tables, __filename);
  }

  public async ExportWithObblighiFormativi(enteId: string) {
    const ente = await enteService.Detail(enteId);

    const data = await this.Stats(enteId);

    const __filename = `${`${ente.nome.replace(/ /gi, '-').toLowerCase()}`}_${formatTime(new Date(), 'YYYYMMDDHHmmss')}`;

    const tables: any[] = [];
    tables.push({
      header: ['              ', 'Obbligo formativo', 'Obbligo formativo ridotto', 'Nessun obbligo formativo', 'Totali'],
      data: [
        ['Registrati'].concat(data.counters.obbligoFormativoIscritti.map(m => m.count.toString())).concat(data.counters.iscritti.toString()),
        ['Non Registrati'].concat(data.counters.obbligoFormativoNonIscritti.map(m => m.count.toString())).concat(data.counters.nonIscritti.toString()),
        ['Totali']
          .concat(data.counters.obbligoFormativoIscritti.map((m, index) => {
            return (m.count + (data.counters.obbligoFormativoNonIscritti[index]?.count || 0)).toString();
          }))
          .concat((data.counters.iscritti + data.counters.nonIscritti).toFixed())
      ],
      wsName: `Statistiche utenti`,
      merges: [],
      autoWidth: true
    });

    tables.push({
      header: [
        'Nome utente',
        'Data Creazione Utenza',
        'E-Mail confermata',
        'Utente bloccato',
        'Accesso tramite SPID',
        'Nome completo',
        'Cognome',
        'Nome',
        'Codice fiscale',
        'Ordine Regionale',
        'Adempiente triennio formativo precedente',
        'Obbligo formativo',
        'Data di nascita',
        'Comune di nascita',
        'E-Mail',
        'PEC',
        'Telefono',
        'Tessera',
        'Categoria',
        'Data prima iscrizione',
        'Data iscrizione categoria',
        'Data ultimo provvedimento',
        'Data inizio rinuncia',
        'Data fine rinuncia',
        'Data inizio cancellazione',
        'Data fine cancellazione',
        'Data pensione',
        'Data decesso',
        'Data iscrizione regione',
        'Indirizzo',
        'CAP',
        'Comune',
        'Provincia',
        'Nazione'
      ],
      data: data.iscritti.map((m) => [
        m.userName,
        m.userCreationDate,
        m.emailConfirmed,
        m.lockoutEnabled,
        m.spid,
        m.nomeCompleto,
        m.cognome,
        m.nome,
        m.codiceFiscale,
        m.ordineRegionale,
        m.adempiente == 2 ? 'Nuovo Iscritto' : m.adempiente == 1 ? 'Adempiente' : 'Non adempiente',
        m.obbligoFormativo ? uppercaseFirstChar(m.obbligoFormativo) : null,
        !m.dataNascita || (m.dataNascita && m.dataNascita.getFullYear() > 1800) ? m.dataNascita : 'Data non valida',
        m.comuneNascita,
        m.mail,
        m.pec,
        m.telefono,
        m.tessera,
        !m.categoria || (m.categoria && !m.categoria.indexOf('_SIGEF')) ? m.categoria : m.categoria.replace('_SIGEF', ''),
        !m.dataPrimaIscrizione || (m.dataPrimaIscrizione && m.dataPrimaIscrizione.getFullYear() > 1900) ? m.dataPrimaIscrizione : 'Data non valida',
        !m.dataIscrizioneCategoria || (m.dataIscrizioneCategoria && m.dataIscrizioneCategoria.getFullYear() > 1900) ? m.dataIscrizioneCategoria : 'Data non valida',
        !m.dataProvvedimento || (m.dataProvvedimento && m.dataProvvedimento.getFullYear() > 1900) ? m.dataProvvedimento : 'Data non valida',
        !m.dataInizioRinuncia || (m.dataInizioRinuncia && m.dataInizioRinuncia.getFullYear() > 1900) ? m.dataInizioRinuncia : 'Data non valida',
        !m.dataFineRinuncia || (m.dataFineRinuncia && m.dataFineRinuncia.getFullYear() > 1900) ? m.dataFineRinuncia : 'Data non valida',
        !m.dataInizioCancellazione || (m.dataInizioCancellazione && m.dataInizioCancellazione.getFullYear() > 1900) ? m.dataInizioCancellazione : 'Data non valida',
        !m.dataFineCancellazione || (m.dataFineCancellazione && m.dataFineCancellazione.getFullYear() > 1900) ? m.dataFineCancellazione : 'Data non valida',
        !m.dataPensione || (m.dataPensione && m.dataPensione.getFullYear() > 1900) ? m.dataPensione : 'Data non valida',
        !m.dataDecesso || (m.dataDecesso && m.dataDecesso.getFullYear() > 1900) ? m.dataDecesso : 'Data non valida',
        !m.dataIscrizioneRegione || (m.dataIscrizioneRegione && m.dataIscrizioneRegione.getFullYear() > 1900) ? m.dataIscrizioneRegione : 'Data non valida',
        m.indirizzo,
        m.cap,
        m.comune,
        m.provincia,
        m.nazione
      ]),
      wsName: `Giornalisti Registrati`,
      merges: [],
      autoWidth: true
    });

    tables.push({
      header: [
        'Nome completo',
        'Cognome',
        'Nome',
        'Codice fiscale',
        'Ordine Regionale',
        'Adempiente triennio formativo precedente',
        'Obbligo formativo',
        'Data di nascita',
        'Comune di nascita',
        'E-Mail',
        'PEC',
        'Telefono',
        'Tessera',
        'Categoria',
        'Data prima iscrizione',
        'Data iscrizione categoria',
        'Data ultimo provvedimento',
        'Data inizio rinuncia',
        'Data fine rinuncia',
        'Data inizio cancellazione',
        'Data fine cancellazione',
        'Data pensione',
        'Data decesso',
        'Data iscrizione regione',
        'Indirizzo',
        'CAP',
        'Comune',
        'Provincia',
        'Nazione'
      ],
      data: data.nonIscritti.map((m) => [
        m.nomeCompleto,
        m.cognome,
        m.nome,
        m.codiceFiscale,
        m.ordineRegionale,
        m.adempiente == 2 ? 'Nuovo Iscritto' : m.adempiente == 1 ? 'Adempiente' : 'Non adempiente',
        m.obbligoFormativo ? uppercaseFirstChar(m.obbligoFormativo) : null,
        !m.dataNascita || (m.dataNascita && m.dataNascita.getFullYear() > 1800) ? m.dataNascita : 'Data non valida',
        m.comuneNascita,
        m.mail,
        m.pec,
        m.telefono,
        m.tessera,
        !m.categoria || (m.categoria && !m.categoria.indexOf('_SIGEF')) ? m.categoria : m.categoria.replace('_SIGEF', ''),
        !m.dataPrimaIscrizione || (m.dataPrimaIscrizione && m.dataPrimaIscrizione.getFullYear() > 1900) ? m.dataPrimaIscrizione : 'Data non valida',
        !m.dataIscrizioneCategoria || (m.dataIscrizioneCategoria && m.dataIscrizioneCategoria.getFullYear() > 1900) ? m.dataIscrizioneCategoria : 'Data non valida',
        !m.dataProvvedimento || (m.dataProvvedimento && m.dataProvvedimento.getFullYear() > 1900) ? m.dataProvvedimento : 'Data non valida',
        !m.dataInizioRinuncia || (m.dataInizioRinuncia && m.dataInizioRinuncia.getFullYear() > 1900) ? m.dataInizioRinuncia : 'Data non valida',
        !m.dataFineRinuncia || (m.dataFineRinuncia && m.dataFineRinuncia.getFullYear() > 1900) ? m.dataFineRinuncia : 'Data non valida',
        !m.dataInizioCancellazione || (m.dataInizioCancellazione && m.dataInizioCancellazione.getFullYear() > 1900) ? m.dataInizioCancellazione : 'Data non valida',
        !m.dataFineCancellazione || (m.dataFineCancellazione && m.dataFineCancellazione.getFullYear() > 1900) ? m.dataFineCancellazione : 'Data non valida',
        !m.dataPensione || (m.dataPensione && m.dataPensione.getFullYear() > 1900) ? m.dataPensione : 'Data non valida',
        !m.dataDecesso || (m.dataDecesso && m.dataDecesso.getFullYear() > 1900) ? m.dataDecesso : 'Data non valida',
        !m.dataIscrizioneRegione || (m.dataIscrizioneRegione && m.dataIscrizioneRegione.getFullYear() > 1900) ? m.dataIscrizioneRegione : 'Data non valida',
        m.indirizzo,
        m.cap,
        m.comune,
        m.provincia,
        m.nazione
      ]),
      wsName: `Giornalisti non Registrati`,
      merges: [],
      autoWidth: true
    });

    export2Excel(tables, __filename);
  }
}

export const exportUsersService = new ExportUsersService()
