import { Vue, Component, Prop } from "vue-property-decorator";
import { rolesServices } from "@/modules/roles/services/rolesServices";
import { userService } from "../services/userService";
import roleAutocomplete from "@/modules/roles/components/roleAutocomplete/roleAutocomplete.vue";

@Component({
  components: {
    roleAutocomplete
  }
})
export default class UserInRoles extends Vue {
  @Prop({ required: true })
  userId: string

  list: {name: string}[] = [];
  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.list = (await userService.getUserRoles(this.userId)).map(m => {
      return { name: m };
    }) || [];
  }

  async handleDelete(row:  { name: string }, index: number) {
    try {
      this.$confirm("Vuoi rimuovere l'associazione?", "Attenzione!", {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annulla',
        type: 'warning'
      }).then(async () => {
        await rolesServices.removeUserFromRole(row.name, this.userId)
        this.list.splice(index, 1)
        this.$message({
          message: "Utente rimosso con successo",
          type: "success",
        })
      })
    } catch (error) {
      this.$message({
        message: "Errore nella rimozione dell'utente",
        type: "error",
      })
    }
  }

  role: string = null;
  async handleAdd() {
    try {
      const result = await rolesServices.assignRoleToUser(this.role, this.userId)

      this.$message({
        message: "Utente assegnato correttamente",
        type: "success",
      })

      await this.fetchData();

      return result;

    } catch (error) {
      this.$message({
        message: "Errore nell'assegnazione del ruolo",
        type: "error",
      })
    }
  }
}
