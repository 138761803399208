import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class passwordValidator extends Vue {

  @Prop()
  value : {
    length: boolean,
    digit: boolean,
    uppercase: boolean,
    lowercase: boolean,
    special: boolean,
    unequal: boolean,
  };
}
