import { render, staticRenderFns } from "./passwordValidator.vue?vue&type=template&id=05b5c482&scoped=true"
import script from "./passwordValidator.ts?vue&type=script&lang=ts&external"
export * from "./passwordValidator.ts?vue&type=script&lang=ts&external"
import style0 from "./passwordValidator.vue?vue&type=style&index=0&id=05b5c482&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b5c482",
  null
  
)

export default component.exports