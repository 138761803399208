import { render, staticRenderFns } from "./user-ente-detail.vue?vue&type=template&id=5e1da01d"
import script from "./user-ente-detail.ts?vue&type=script&lang=ts&external"
export * from "./user-ente-detail.ts?vue&type=script&lang=ts&external"
import style0 from "./user-ente-detail.vue?vue&type=style&index=0&id=5e1da01d&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports