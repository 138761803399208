import { Vue, Component, Watch } from 'vue-property-decorator'

import { crogService } from '@/modules/enti/service/crogService';
import { userService } from '../services/userService';
import { store } from '@/store';

import { debounce } from '@/services/_base/debounce';
import { capitalizeFirstLetter } from '@/utils/utils';

import ManageUser from '../components/manage-user.vue'
import GiornalistaDetailDialog from '@/modules/anagrafica/components/giornalistaDetailDialog/giornalistaDetailDailog.vue';
import ExportUsersEntePanel from '../components/exportUsersEntePanel.vue'
import { exportUsersService } from '../services/exportUsersService';

@Component({
  components: {
    ManageUser,
    GiornalistaDetailDialog,
    ExportUsersEntePanel
  }
})
export default class Utenti extends Vue {
  list: user.summary[] = [] as user.summary[];
  manage: user.item = null;
  manageVisible = false;
  showDetailGiornalista = false;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 40, 60],
    total: 0
  }

  public query: user.queryParams = {
    page: 1,
    take: 20,
    orderField: 'Cognome',
    orderDescending: false,
    search: null
  };
  anagraficaEnteId: string = null;

  @Watch('query.search')
  queryChanged(n, o) {
    if (n !== o) {
      this.loading = true;
      this.fetchDataDebounced(this);
    }
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await self.fetchData()
  }, 400);

  ordiniRegionali: ente.crog[] = [];
  async created() {
    if (this.$can('USERS.enabled') || this.$can('USERS.viewOrg')) {
      if (this.$can('USERS.viewOrg') && !this.$can('USERS.enabled')) {
        this.anagraficaEnteId = store.getters.login.userInfo()?.anagraficaEnteId;
        this.query.anagraficaEnteId = this.anagraficaEnteId;
      }
      this.ordiniRegionali = await crogService.List();
      await this.fetchData();
    }
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await userService.Count(this.query);
    this.list = await userService.List(this.query);
    this.loading = false;
  }

  handleEdit(row: user.summary) {
    this.manage = row;
    this.showDetailGiornalista = false;
    this.manageVisible = true;
  }

  handleDelete(row: user.item) {
    this.$msgbox({
      title: 'Attenzione',
      message:
        `L'utente <strong>${row.userName} </strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(() => {
      userService.Remove(row.id).then(async () => {
        this.$message({
          message: `L'utente <strong>${row.userName}</strong> è stato cancellato correttamente`,
          dangerouslyUseHTMLString: true,
          type: 'success',
          duration: 3600
        })
        await this.fetchData();
      }).catch((error) => {
        console.error(error)
        this.$message({
          message: `Impossibile cancellare l'utente <strong>${row.userName}</strong>
                      <br>L'istruzione di cancellazione è in conflitto con i vincoli che l'utente ha verso le altre sezioni del sito`,
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 7200
        })
      })
    }).catch((error) => {
      console.error(error)
    })
  }

  async handleToggleLocked(row: user.item) {
    if(this.$can('USERS.canedit')){
    const oldObj = Object.assign({}, row);
    userService.ToggleLocked(row.id).then((user) => {
      if (user) {
        row.lockoutEnabled = user.lockoutEnabled;
        this.$message({
          message: `L'utente <strong>${user.userName}</strong> è stato <strong>${user.lockoutEnabled ? 'bloccato' : 'sbloccato'}</strong> con successo`,
          type: 'success',
          dangerouslyUseHTMLString: true,
          duration: 7200
        })
      }
    }).catch(() => {
      this.loading = false;
      this.$message({
        message: `Si è verificato un errore nell'aggiornamento dello stato dell'account <strong>${row.userName}</strong>`,
        type: 'error',
        dangerouslyUseHTMLString: true,
        duration: 7200
      });
      Object.assign(row, oldObj);
    })}
  }

  async onAdd(elem: user.item) {
    this.fetchData();
  }

  async onChange(elem: user.item) {
    this.fetchData();
    this.onClose();
  }

  onShowDetailGiornalista(user: user.item) {
    this.manage = user;
    this.manageVisible = false;
    this.showDetailGiornalista = true;
  }

  onClose() {
    this.manage = null;
    this.manageVisible = false;
    this.showDetailGiornalista = false;
  }

  ordine(anagraficaEnteId: string) {
    if (this.ordiniRegionali.length <= 0) return null;
    const ordine = this.ordiniRegionali.find(f => f.id === anagraficaEnteId);
    return ordine?.nome;
  }

  downloading: boolean = false;
  async handleExport() {
    if (this.anagraficaEnteId)
      try {
        this.downloading = true;
        await exportUsersService.Export(this.anagraficaEnteId);
        this.downloading = false;
      } catch (error) {
        console.log(error);
        this.downloading = false;
      }
  }
}
