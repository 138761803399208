import { Vue, Prop, Component } from "vue-property-decorator";
import { accountServices } from "@/modules/account/services/accountService";
import { userService } from "../services/userService";
import { ElForm } from "element-ui/types/form";
import passwordValidator from "@/components/passwordValidator/passwordValidator.vue";
import UserInRoles from "./user-in-roles.vue";
import GiornalistaDetail from '@/modules/anagrafica/components/giornalistaDetail/giornalistaDetail.vue'
import GiornalistaAutocomplete from "@/modules/anagrafica/components/giornalistaAutocomplete/giornalistaAutocomplete.vue";
import CreditiGiornalistaOverview from '@/modules/offertaFormativa/components/crediti-giornalista-overview.vue'
import EnteAutocomplete from '@/modules/enti/components/enteAutocomplete.vue';
import UserEnteDetail from '@/modules/enti/components/userDetail/user-ente-detail.vue'


@Component({
  components: {
    passwordValidator,
    UserInRoles,
    GiornalistaDetail,
    CreditiGiornalistaOverview,
    EnteAutocomplete,
    UserEnteDetail,
    GiornalistaAutocomplete
  }
})
export default class CreateUser extends Vue {
  @Prop()
  id: string;
  userInRoleValid: boolean = true;

  value: user.item = null;
  manage: user.item = {
    id: null,
    phoneNumber: null,
    emailConfirmed: false,
    email: null,
    userName: null,
    codiceFiscale: null,
    nome: null,
    cognome: null,
    anagraficaEnteId: null,
    anagraficaGiornalistaId: null,
    spid: false
  } as user.item;

  activeTab: string = "generale";

  enteTipo: "azienda" | "cts" | "crog" | "cnog" | "etf" = null;

  async created() {
    if (this.id) {
      this.value = await userService.Detail(this.id);
    }
    if (this.value) Object.assign(this.manage, this.value);
  }

  get validationRules() {
    return {
      userName: [
        { required: true, message: 'Inserire uno Username', trigger: 'blur' },
        { min: 3, message: 'Lo Username deve avere almeno tre caratteri, e non può contenere spazi', trigger: 'blur' },
        {
          validator: (rule, value, callback) => this.checkUsernameExist(rule, value, callback),
          trigger: 'blur'
        }
      ],
      nome: [
        { required: true, message: 'Inserire il Nome', trigger: 'blur' }
      ],
      cognome: [
        { required: true, message: 'Inserire il Cognome', trigger: 'blur' }
      ],
      codiceFiscale: [
        { required: false, message: 'Inserire il Codice Fiscale', trigger: 'blur' }
      ],
      email: [
        { required: true, message: `Inserire l'E-mail`, trigger: 'blur' },
        { type: 'email', message: `L'E-mail inserita non è nel formato corretto`, trigger: 'blur' }
      ],
      // phoneNumber: [
      //   { required: true, message: 'Inserire il numero di telefono', trigger: 'blur' }
      // ],
    }
  }

  private async checkUsernameExist(rule, value:string, callback) {
    if (value && value.toLowerCase() !== this.value?.userName?.toLowerCase()) {
      if (value.indexOf(' ') >= 0){
        callback(new Error(`Lo Username non deve contenere spazi`));
        return;
      }
      const result = await accountServices.CheckUsernameExist(value);
      if (result) {
        callback(new Error(`E' già presente un utente con questo Nome Utente`));
        return;
      }
    }
    callback();
  }

  saving: boolean = false;
  async create() {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.saving = true;
          const user = await userService.Create(this.manage);
          if (user) {
            // await userService.ResetPassword(userCreated, this.user.password);
            this.$message({ message: 'Utente creato con successo', type: 'success' });
            this.value = Object.assign({}, user)
            this.manage = Object.assign({}, user)
            this.$emit('add', user);
          }
          this.saving = false;
        } catch (e) {
          this.$message({ message: "Errore nella creazione dell\'utente", type: 'warning' });
          this.saving = false;
        }
      }
    })
  }

  async update() {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.saving = true;
          const user = await userService.Update(this.manage);
          this.$message({ message: "Utente aggiornato con successo", type: "success" })
          this.saving = false;
          this.$emit('change', user);
        } catch (error) {
          this.$message({ message: "Errore nell'aggiornamento dell'utente", type: "error" })
          this.saving = false;
        }
      }
    })
  }

  async updateAnagraficaGiornalistaId() {
      if (this.manage.anagraficaGiornalistaId) {
        try {
          this.saving = true;
          this.value.anagraficaGiornalistaId = this.manage.anagraficaGiornalistaId;
          const user = await userService.Update(this.value);
          this.$message({ message: "Anagrafica Giornalista associata con successo", type: "success" })
          this.saving = false;
        } catch (error) {
          this.$message({ message: "Errore nell'associazione della Anagrafica Giornalista all'utenza selezionata", type: "error" })
          this.saving = false;
        }
      } else {
        this.$message({ message: "E' necessario selezionare un Anagrafica Giornalista", type: "info" })
        this.saving = false;
      }
  }

  undo() {
    if (this.value) Object.assign(this.manage, this.value);
  }
}