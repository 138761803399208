import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import RightPanel from "@/components/RightPanel/index.vue";
import { ElForm } from "element-ui/types/form";
import { exportUsersService } from "../services/exportUsersService";
import { crogService } from "@/modules/enti/service/crogService";
@Component({
  components: {
    RightPanel
  }
})

export default class ExportUsersEntePanel extends Vue {
  @Prop({ default: null, required: false })
  anagraficaEnteId: string;

  model: { enteId: string } = { enteId: null }

  get formRules() {
    return {
      enteId: [
        { required: true, message: `Inserisci un ente`, trigger: 'blur' },
        { required: true, message: `Inserisci un ente`, trigger: 'change' }
      ]
    };
  }

  ordiniRegionali: ente.crog[] = [];
  async created() {
    this.ordiniRegionali = await crogService.List();
    this.model.enteId = this.anagraficaEnteId;
  }

  @Watch('anagraficaEnteId')
  anagraficaEnteIdChange(n, o) {
    if (n !== o) {
      this.model.enteId = this.anagraficaEnteId;
    }
  }

  downloading: boolean = false;
  async handleExport() {
    (this.$refs.exportLogsForm as ElForm).validate(async valid => {
      if (valid) {
        try {
          this.downloading = true;
          await exportUsersService.Export(this.model.enteId);
          this.downloading = false;
        } catch (error) {
          console.log(error);
          this.downloading = false;
        }
      }
    });
  }
}