import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { enteService } from "../../service/enteService";

@Component({})
export default class UserEnteDetail extends Vue {
  @Prop({required: true})
  id: string;
  manage: ente.item = null;
  
  async created() {
    this.manage = await enteService.Detail(this.id)
  }
}